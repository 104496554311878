import styled from "styled-components"
import { Link as LinkBase } from "../../../../common/Link"

export const StyledLink = styled(LinkBase)`
  padding-left: 0.7rem;
  font-size: 12pt;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  justify-content: flex-start;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
`

export const Link = styled(LinkBase)``

export const ItemBase = styled.div`
  border: none;
  margin: 0;
  & ${StyledLink} {
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    transition-timing-function: ease-in;
    text-decoration: none;
    font-family: "Ebrima", Arial;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }
  /* &:focus {
    border: solid;
    border: transparent;
  }
  &:focus::after {
    background: transparent;
    transform: scale(2);
  }

  &:active {
    transform: scale(0.99);
  } */
`

export const Domaine = styled(ItemBase)`
  width: 100%;
  padding: 1rem 0;
`

export const ItemDomaine = styled(StyledLink)`
  /* border-bottom: 1.2px solid; */
  padding: 1rem;
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Categorie = styled(ItemBase)`

  & ${StyledLink} {
    color: #bd9d6d;
    text-decoration: none;
    font-family: "Ebrima", Arial;
    font-size: 10pt;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    text-transform: uppercase;
    margin-left: 3em;
  }
`

export const SousCategorie = styled(ItemBase)`
  font-family: "Ebrima", Arial;
  font-size: 10pt;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  text-decoration: underline;
  flex-wrap: wrap;
  & ${StyledLink} {
    color: #333333;
  }
`
