import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"

import {
  Wrapper,
  Domaine,
  Categorie,
  ItemWrapper,
  SousCategorie,
  StyledLink,
  ItemDomaine,
  Link,
} from "./DomaineItemS.style"

interface Props {
  className?: string
  data: Object
}

export function DomaineItemSView({ className, data }: Props) {
  return (
    <Wrapper className={className}>
      <Domaine>
        <ItemDomaine>
          {data.children?.edges && data.name?.toUpperCase()}
          {!data.children?.edges && (
            <Link link={"/" + data.slug}>{data.name?.toUpperCase()}</Link>
          )}
        </ItemDomaine>
        {data.children?.edges && (
          <ItemWrapper>
            {data.children.edges.map(child => {
              return (
                <Categorie key={uuidv4()}>
                  <StyledLink link={"/" + child.node.slug}>
                    {child.node.name}
                  </StyledLink>
                  {child.node.children.edges.map((sousCategorie: any) => {
                    return (
                      <SousCategorie key={uuidv4()}>
                        <StyledLink link={"/" + sousCategorie.node.slug}>
                          {sousCategorie.node.name}
                        </StyledLink>
                      </SousCategorie>
                    )
                  })}
                </Categorie>
              )
            })}
          </ItemWrapper>
        )}
      </Domaine>
    </Wrapper>
  )
}
