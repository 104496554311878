import { useStaticQuery, graphql } from "gatsby"

export const frag_MenuItem = graphql`
  fragment frag_MenuCategoryItem on SALEOR_Category {
    name
    seoTitle
    seoDescription
    name
    slug
    level
  }
  fragment frag_MenuCollectionItem on SALEOR_Collection {
    name
    seoTitle
    seoDescription
    name
    slug
  }
`

export const usePlanDuSiteQuery = () => {
  const data = useStaticQuery(
    graphql`
      query GetPlanDuSite {
        saleor {
          menus(filter: { search: "navbar" }, first: 100, channel: "default-channel") {
            totalCount
            edges {
              node {
                name
                items {
                  category {
                    ...frag_MenuCategoryItem
                    children(first: 100) {
                      edges {
                        node {
                          ...frag_MenuCategoryItem
                          children(first: 100) {
                            edges {
                              node {
                                ...frag_MenuCategoryItem
                              }
                            }
                          }
                        }
                      }
                    }
                    backgroundImage {
                      url
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}
