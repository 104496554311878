import styled from "styled-components"
import { Link as LinkBase } from "../../../common/Link"

export const StyledLink = styled(LinkBase)`
  padding-left: 0.7rem;
  font-size: 12pt;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
`

import {
  CommonTitle,
  CommonSubtitle,
  CommonParagraph,
} from "../../../common/Texts"

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`

export const MidleColumn = styled.div``

export const Title = styled(CommonTitle)``

export const Subtitle = styled(CommonSubtitle)``

export const Paragraph = styled(CommonParagraph)``

export const Link = styled(LinkBase)``

export const ItemBase = styled.div`
  border: none;
  margin: 0;
  & ${StyledLink} {
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    transition-timing-function: ease-in;
    text-decoration: none;
    font-family: "Ebrima", Arial;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
  }
`

export const Categorie = styled(ItemBase)`
  & ${StyledLink} {
    color: #bd9d6d;
    text-decoration: none;
    font-family: "Ebrima", Arial;
    font-size: 10pt;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    text-transform: uppercase;
  }
`

export const SousCategorie = styled(ItemBase)`
  text-decoration: none;
  font-family: "Ebrima", Arial;
  font-size: 10pt;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-left: 1rem;
  & ${StyledLink} {
    color: #333333;
  }
`
